import { Link } from '@redwoodjs/router'
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'

import { useLazyQuery } from '@apollo/client'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined'
import EditIcon from '@mui/icons-material/Edit'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined'
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { Typography, Link as MuiLink } from '@mui/material'
import {
  getLastComplianceTimeString,
  getMaxNextDueString,
  getNextDueString,
} from '@wingwork/common/src/maintenanceItem'

import dayjs from 'dayjs'
import { get } from 'lodash'
import {
  MaintenanceItem,
  MaintenanceItemFilters,
  RemainingValueFilter,
} from 'types/graphql'

import { routes, useLocation } from '@redwoodjs/router'

import ApiError from 'src/components/ApiError'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import Loading from 'src/components/Loading'
import { GET_SAVED_COLUMNS_CONFIG } from 'src/components/ManageTableColumnsModal'
import IconButton from 'src/components/MUI/IconButton'
import { HighlightedTypography, MultiLineTypography } from 'src/components/MUI/StyledComponents'
import useAircraftList from 'src/hooks/requests/useAircraftRecords'
import usePaginatedMaintenanceItemList from 'src/hooks/requests/usePaginatedMaintenanceItemList'
import { useDispatch } from 'src/hooks/useDispatch'
import useMaintenanceItemTable, {
  TableVariant,
} from 'src/hooks/useMaintenanceItemTable'
import { useOrgName } from 'src/hooks/useOrgName'
import { formatDateForDisplayInUtc } from 'src/utils/helpers'
import { inProgressValues } from 'src/utils/maintenanceItem'
import {
  getIntervals,
  getTolerances,
} from '@wingwork/common/src/maintenanceItem'

import TypeAndDescriptionDisplay, {
  ChipData,
} from '../../TypeAndDescriptionDisplayV2'

import LineItemDetailsDrawer from './LineItemDetails/LineItemDetailsDrawerV2'
import AtaCodeCell from './AtaCodeCell'
import { useEffect, useMemo, useState, useRef, useCallback } from 'react'

const HIGHLIGHT_CARD_FILTER_COLUMN_MAP = {
  adhocOnlyNotDue: [
    'tailNumber',
    'ataCode',
    'typeDescription',
    'lastComplianceDate',
    'actions',
  ],
}

export type SortOrder = 'asc' | 'desc'

interface TableWithDataProps {
  variant: TableVariant
  onSelectItem?: (id: string | string[], isSelect?: boolean) => void
  selectedAircraft?: string
  setQueryVariables?: (queryVariables: MaintenanceItemFilters) => void
  queryVariables?: MaintenanceItemFilters
  disableTaskSelection?: boolean
  taskViewMode?: 'simple' | 'advanced'
  setTableTitle?: (title: string) => void
  discriminator?:
    | ''
    | 'associatedTasks'
    | 'componentRelatedMaintenanceItems'
    | 'unscheduledTasks'
    | 'dueListPicker'
  onChildTaskSliderClick?: (row: MaintenanceItem) => void
  enableRowVirtualization: boolean
  tableHeightSx?: string
}

const TableWithData: React.FC<TableWithDataProps> = ({
  variant,
  onSelectItem,
  selectedAircraft,
  disableTaskSelection = false,
  taskViewMode = 'advanced',
  setQueryVariables,
  queryVariables = {
    isChildItem: false,
    onlyDueItems: true,
    parentId: null,
    showInProgress: true,
  },
  setTableTitle,
  discriminator = '',
  onChildTaskSliderClick,
  enableRowVirtualization,
  tableHeightSx = '100%',
}) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: 'nextDueStatus',
      desc: false,
    },
  ])

  const dispatch = useDispatch()

  const [detailedTaskView, setDetailedTaskView] = useState(undefined)
  const [detailedTaskViewTab, setDetailedTaskViewTab] = useState(0)
  const [openDetailedTaskView, setOpenDetailedTaskView] = useState(false)
  const orgName = useOrgName()

  const {
    duelistFiltersData,
    getSearchConfig,
    setEnabledColumns,
    showChildItems,
    selectedItemIds,
    unselectAll,
    selectMaintenanceItem,
    deselectMaintenanceItem,
  } = useMaintenanceItemTable(variant)

  const {
    filterDataBy,
    powerSearchTerms,
    fuzzySearchTerms,
    aircrafts: aircraftFilter,
    trackedBy,
    timeFrameAll,
    timeframeCycles,
    timeframeDays,
    timeframeHours,
    timeframeLandings,
    projectionFilterData,
    generalFilterData,
    ataCodes,
  } = duelistFiltersData

  const {
    maintenanceItems,
    hasNextPage,
    initialLoading,
    error,
    loadMaintenanceItems,
    fetchNextMaintenancePage,
    paginationLoading,
    totalCount,
  } = usePaginatedMaintenanceItemList()

  const [
    getSavedColumnsConfig,
    { data: savedTableConfig, loading: tableColumnConfigLoading },
  ] = useLazyQuery(GET_SAVED_COLUMNS_CONFIG)

  const {
    loadAircraftList,
    loading: isAircraftListLoading,
    hasLoaded: hasAircraftListLoaded,
    aircrafts,
  } = useAircraftList()

  useEffect(() => {
    loadAircraftList({ orgSlug: orgName })
    getSavedColumnsConfig({
      variables: {
        tableName: 'MaintenanceItems',
        orgSlug: orgName,
      },
      fetchPolicy: 'cache-first',
    })
  }, [])

  useEffect(() => {
    if (savedTableConfig?.feTableConfiguration) {
      setEnabledColumns(savedTableConfig.feTableConfiguration?.columns || [])
    }
  }, [savedTableConfig])

  const location = useLocation()

  const currentRequest = useRef<{
    controller: AbortController
    id: number
  } | null>(null)
  const requestCounter = useRef(0)

  useEffect(() => {
    const abortController = new AbortController()
    const requestId = ++requestCounter.current

    // Abort previous request if it exists (before starting new one)
    if (currentRequest.current) {
      currentRequest.current.controller.abort()
    }

    // Store new request as current
    currentRequest.current = { controller: abortController, id: requestId }

    const loadData = async () => {
      //Constructing Aircraft Filter

      // Only proceed if this is still the current request (prevents race condition with stale data)
      if (currentRequest.current?.id === requestId) {
        const aircraftIds = queryVariables?.aircraftIds
          ? queryVariables?.aircraftIds
          : Object.keys(aircraftFilter).filter(
              (aircraftId) => aircraftFilter[aircraftId]
            )
        //Constructing Remaining Value Filter
        let remainingValueFilter = {}
        if (timeframeDays.days > 0) {
          remainingValueFilter = {
            type: 'CALENDAR_DAYS',
            value: timeframeDays.days,
          }
        } else if (timeframeHours.hours > 0) {
          remainingValueFilter = {
            type: 'FLYING_HOURS',
            value: timeframeHours.hours,
          }
        } else if (timeframeCycles.cycles > 0) {
          remainingValueFilter = {
            type: 'CYCLES',
            value: timeframeCycles.cycles,
          }
        } else if (timeframeLandings.landings > 0) {
          remainingValueFilter = {
            type: 'LANDINGS',
            value: timeframeLandings.landings,
          }
        } else {
          remainingValueFilter = undefined
        }

        //Constructing Status Filter and Unscheduled Type Filter (Highlight Cards)
        let statuses = []
        const unscheduledType = queryVariables?.unscheduledType ?? []
        let onlyDueItems = queryVariables?.onlyDueItems ? true : false
        filterDataBy.forEach((filter) => {
          switch (filter) {
            case 'pastDueAndAog':
              statuses.push('OVERDUE')
              break
            case 'inToleranceAndComingDue':
              statuses.push('IN_TOLERANCE')
              statuses.push('APPROACHING_DUE')
              break
            case 'discrepanciesAndMel':
              // Empty the array
              unscheduledType.splice(0, unscheduledType.length)
              unscheduledType.push('DISCREPANCY')
              unscheduledType.push('MEL')
              break
            // These values needs to be in sync with stats backend.
            case 'adsb':
              // Empty the array
              unscheduledType.splice(0, unscheduledType.length)
              unscheduledType.push('AIRWORTHINESS_DIRECTIVE')
              unscheduledType.push('AD_SB')
              unscheduledType.push('SPECIAL_AD')
              unscheduledType.push('RECURRING_AD')
              unscheduledType.push('EMERGENCY_AD')
              unscheduledType.push('ALERT_SB')
              unscheduledType.push('MANDATORY_SB')
              unscheduledType.push('SERVICE_LETTER')
              unscheduledType.push('SERVICE_BULLETIN')
              break
            case 'ads':
              // Empty the array
              unscheduledType.splice(0, unscheduledType.length)
              unscheduledType.push('AIRWORTHINESS_DIRECTIVE')
              unscheduledType.push('AD_SB')
              unscheduledType.push('SPECIAL_AD')
              unscheduledType.push('RECURRING_AD')
              unscheduledType.push('EMERGENCY_AD')
              break
            case 'sbs':
              // Empty the array
              unscheduledType.splice(0, unscheduledType.length)
              unscheduledType.push('ALERT_SB')
              unscheduledType.push('MANDATORY_SB')
              unscheduledType.push('SERVICE_LETTER')
              unscheduledType.push('SERVICE_BULLETIN')
              break
            case 'adhocOnlyNotDue':
              statuses.push('NOT_DUE')
              break
            case 'adhocAndDuelist':
              onlyDueItems = false
              break
            default:
              break
          }
        })

        const { date, projectionGrid } = projectionFilterData
        const {
          statuses: projectionStatuses,
          cadenceType,
          ...otherGeneralFilters
        } = generalFilterData
        statuses = statuses.concat(projectionStatuses ?? [])
        const showDueInNextNDays =
          date !== undefined ? dayjs(date).diff(dayjs(), 'days') : undefined

        const { id: sortField, desc } = sorting?.[0]
        const sortOrder = desc ? 'desc' : 'asc'
        const updatedQueryVariables = {
          ...queryVariables,
          ...getSearchConfig().variables,
          showChildItems,
          onlyDueItems,
          aircraftIds,
          statuses,
          unscheduledType,
          sortOrder,
          sortField,
          showDueInNextNDays,
          // Intentional empty string check to avoid sending empty string to backend
          cadenceType: cadenceType === '' ? undefined : cadenceType,
          ...otherGeneralFilters,
          ataCodes,
          trackedBy,
        }
        if (remainingValueFilter) {
          updatedQueryVariables['remainingValueFilters'] = [
            remainingValueFilter as RemainingValueFilter,
          ]
        } else {
          // no-dd-sa:typescript-code-style/no-lonely-if
          if (projectionGrid.length > 0 && date !== undefined && date !== '') {
            updatedQueryVariables['remainingValueFilters'] = []
            projectionGrid.forEach((grid) => {
              // TODO Handle APU Hours
              if (grid.metric === 'APU Hours') return
              const projectionGridTypeMapping = {
                Landings: 'LANDINGS',
                Cycles: 'CYCLES',
                Hours: 'FLYING_HOURS',
              }
              updatedQueryVariables['remainingValueFilters'].push({
                type: projectionGridTypeMapping[grid.metric],
                value: grid.projection,
              } as RemainingValueFilter)
            })
          }
        }

        setQueryVariables?.(updatedQueryVariables)
        loadMaintenanceItems(
          updatedQueryVariables,
          true,
          abortController.signal,
          requestId
        )
      }
    }

    // Promise.resolve() pushes loadData to microtask queue
    // new request starts after all cleanup is complete or else the new request might get aborted before it starts
    Promise.resolve().then(loadData)
  }, [
    powerSearchTerms,
    fuzzySearchTerms,
    aircraftFilter,
    timeFrameAll,
    timeframeDays,
    timeframeHours,
    timeframeCycles,
    timeframeLandings,
    filterDataBy,
    sorting,
    projectionFilterData,
    location.search,
    showChildItems,
    generalFilterData,
    ataCodes,
    trackedBy,
  ])

  const isColumnVisible = (column: string) => {
    const filteredAircrafts = Object.keys(aircraftFilter).filter(
      (key) => aircraftFilter[key]
    )
    if (
      column === 'tailNumber' &&
      (filteredAircrafts.length === 1 || Object.keys(aircrafts).length === 1)
    ) {
      let tailNumber = ''
      if (filteredAircrafts.length === 1) {
        tailNumber = aircrafts[filteredAircrafts[0]]?.tailNumber
      } else if (Object.keys(aircrafts).length === 1) {
        tailNumber = aircrafts[Object.keys(aircrafts)[0]]?.tailNumber
      }
      setTableTitle?.(
        tailNumber
          ? `${tailNumber} ${variant === 'duelist' ? 'Due' : 'Tasks'} List`
          : `${variant === 'duelist' ? 'Fleet Due' : 'Tasks'} List`
      )
      return false
    } else if (column === 'tailNumber') {
      setTableTitle?.(`${variant === 'duelist' ? 'Fleet Due' : 'Tasks'} List`)
    }
    if (filterDataBy.length === 0) return true
    return HIGHLIGHT_CARD_FILTER_COLUMN_MAP[filterDataBy[0]]
      ? HIGHLIGHT_CARD_FILTER_COLUMN_MAP[filterDataBy[0]].includes(column)
      : true
  }

  const onDetailTaskViewClick = (event, row) => {
    event.stopPropagation()
    setDetailedTaskView(row)
    setOpenDetailedTaskView(true)
  }

  const columns = useMemo<MRT_ColumnDef<MaintenanceItem>[]>(
    () => [
      {
        accessorKey: 'tailNumber',
        header: 'Tail No.',
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => (
          <div className="flex flex-col">
            <MuiLink
              variant="body1"
              color="inherit"
              component={Link}
              to={routes.planeDashboard({
                orgName: orgName,
                id: row.original.aircraftId,
              })}
            >
              <HighlightedTypography variant="body2">
                {aircrafts[row.original.aircraftId]?.tailNumber}
              </HighlightedTypography>
            </MuiLink>
            <Typography variant="caption">
              {aircrafts[row.original.aircraftId]?.model}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: 'ataCode',
        header: 'ATA Code',
        size: 250,
        Cell: ({ row }) => <AtaCodeCell row={row.original} />,
      },
      {
        accessorKey: 'typeDescription',
        header: 'Type/Description',
        minSize: 400,
        grow: true,
        Cell: ({ row }) => {
          const { enabledColumns } = useMaintenanceItemTable(variant)
          const aircraftComponent = get(row.original, ['aircraftComponent'], {})
          const partNumber = get(aircraftComponent, 'partNumber')
          const serialNumber = get(aircraftComponent, 'serialNumber')
          const chips: ChipData[] = [
            ...(row.original.disposition
              ? [
                  {
                    label: row.original.disposition,
                  },
                ]
              : []),
            ...(row.original.childCount > 0
              ? [
                  {
                    label: 'Parent',
                    onClick: (e) => {
                      e.stopPropagation()
                      console.log('Parent chip clicked')
                      setDetailedTaskViewTab(1)
                      onDetailTaskViewClick(e, row.original)
                    },
                  },
                ]
              : []),
            ...(row.original.parentId !== null
              ? [
                  {
                    label: 'Child',
                  },
                ]
              : []),
            ...row.original.tags.map((tag) => ({
              label: tag.name,
            })),
          ]
          const tailTags = []
          if (!enabledColumns.includes('partSerial')) {
            if (partNumber) tailTags.push(`PN ${partNumber}`)
            if (serialNumber) tailTags.push(`SN ${serialNumber}`)
          }
          return (
            <div className="w-full">
              <TypeAndDescriptionDisplay
                description={row.original.title}
                chips={chips}
                highlight
                tailTags={tailTags.length > 0 ? tailTags : undefined}
              />
            </div>
          )
        },
      },
      {
        accessorKey: 'lastComplianceDate',
        header: 'Compliance',
        size: 150,
        Cell: ({ row }) => {
          const lastComplianceTimeArray = getLastComplianceTimeString(
            row.original
          )

          return (
            <div>
              {lastComplianceTimeArray.map((val, index) => (
                <Typography key={index} variant="body2">
                  {val}
                </Typography>
              ))}
            </div>
          )
        },
      },
      {
        accessorKey: 'interval',
        header: 'Interval',
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          const [intervals, tolerances] = useMemo(() => {
            return [getIntervals(row.original), getTolerances(row.original)]
          }, [row.original.id])

          if (intervals.length === 0) {
            return <Typography> ---- </Typography>
          }

          return (
            <div>
              {intervals.map((item, index) => (
                <div className="inline-block w-full" key={index}>
                  <Typography
                    component="span"
                    className="inline-block whitespace-nowrap"
                    variant="body2"
                  >
                    {item}
                  </Typography>
                  {tolerances[index] && (
                    <Typography
                      variant="caption"
                      component="span"
                      className="ml-0.5 inline-block"
                    >
                      + {tolerances[index]}
                    </Typography>
                  )}
                </div>
              ))}
            </div>
          )
        },
      },
      {
        accessorKey: 'nextDueStatus',
        header: 'Next Due',
        size: 120,
        Cell: ({ row }) => {
          const projectedDate = formatDateForDisplayInUtc(
            get(row.original, 'calculatedNextDueAt')
          )

          const nextDueString = getNextDueString(row.original)

          if (nextDueString.length === 0) {
            return <Typography> ---- </Typography>
          }

          return (
            <div
              data-tooltip-id="global-tooltip"
              data-tooltip-content={`Projected Date: ${projectedDate}`}
            >
              {nextDueString.map((item) => (
                <Typography
                  className="whitespace-nowrap"
                  key={item}
                  variant="body2"
                >
                  {item}
                </Typography>
              ))}
            </div>
          )
        },
      },
      {
        accessorKey: 'maxDue',
        header: 'Max Due',
        size: 120,
        enableSorting: false,
        Cell: ({ row }) => {
          const maxNextDueString = getMaxNextDueString(row.original)

          if (maxNextDueString.length === 0) {
            return <Typography> ---- </Typography>
          }

          return (
            <div>
              {maxNextDueString.map((item) => (
                <Typography
                  className="whitespace-nowrap"
                  key={item}
                  variant="body2"
                >
                  {item}
                </Typography>
              ))}
            </div>
          )
        },
      },
      {
        accessorKey: 'calculatedNextDueAt',
        header: 'Remaining',
        size: 120,
        Cell: ({ row }) => (
          <DueStatusChip
            maintenanceItem={row.original}
            aircraft={aircrafts[row.original.aircraftId]}
            useGlobalTooltip
          />
        ),
      },
      {
        accessorKey: 'partSerial',
        header: 'Part/Serial',
        size: 120,
        enableSorting: false,
        Cell: ({ row }) => {
          const aircraftComponent = get(row.original, ['aircraftComponent'], {})
          const partNumber = get(aircraftComponent, 'partNumber')
          const serialNumber = get(aircraftComponent, 'serialNumber')

          return (
            <div className="flex w-full flex-col">
              {partNumber && (
                <Typography
                  variant="caption"
                  component="div"
                  className="truncate"
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={`Part Number: ${partNumber}`}
                >
                  PN {partNumber}
                </Typography>
              )}
              {serialNumber && (
                <Typography
                  variant="caption"
                  component="div"
                  className="mt-1 truncate"
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={`Serial Number: ${serialNumber}`}
                >
                  SN {serialNumber}
                </Typography>
              )}
            </div>
          )
        },
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        grow: false,
        muiTableHeadCellProps: {
          align: 'right',
        },
        enableSorting: false,
        Cell: ({ row }) => {
          const {
            isLedgerInProgress,
            isRevisionInProgress,
            isWorkOrderInProgress,
            isLogbookInProgress,
            isLogbookAwaitingSignature,
          } = inProgressValues(row.original)
          return (
            <div className="flex w-full items-center justify-end gap-0.5">
              {discriminator !== 'dueListPicker' && (
                <>
                  {isLedgerInProgress && (
                    <IconButton
                      id={`open-compliance-button-${row.original.id}`}
                      tooltip="Open compliance"
                      useGlobalTooltip
                      component={Link}
                      to={routes.bulkCompliance({
                        orgName: orgName,
                        ledgerId: row.original.complianceLedger?.id,
                      })}
                    >
                      <FolderOutlinedIcon />
                    </IconButton>
                  )}
                  {isRevisionInProgress && (
                    <IconButton
                      id={`continue-revision-button-${row.original.id}`}
                      tooltip="Continue revision"
                      useGlobalTooltip
                      component={Link}
                      to={routes.bulkCompliance({
                        orgName,
                        ledgerId: row.original.complianceLedger?.id,
                      })}
                    >
                      <DifferenceOutlinedIcon />
                    </IconButton>
                  )}
                  {isWorkOrderInProgress && (
                    <IconButton
                      id={`open-work-order-button-${row.original.id}`}
                      tooltip="Open work order"
                      useGlobalTooltip
                      component={Link}
                      to={routes.workOrder({
                        orgName,
                        id: row.original.workOrderIdInProgress,
                      })}
                    >
                      <WorkOutlineOutlinedIcon />
                    </IconButton>
                  )}
                  {isLogbookInProgress && (
                    <IconButton
                      id={`open-logbook-button-${row.original.id}`}
                      tooltip="Open logbook"
                      useGlobalTooltip
                      component={Link}
                      to={routes.bulkCompliance({
                        orgName,
                        ledgerId: row.original.complianceLedger?.id,
                      })}
                    >
                      <BookOutlinedIcon />
                    </IconButton>
                  )}
                  {isLogbookAwaitingSignature && (
                    <IconButton
                      id={`open-sign-logbook-button-${row.original.id}`}
                      tooltip="E-sign logbook"
                      useGlobalTooltip
                      component={Link}
                      to={routes.bulkCompliance({
                        orgName,
                        ledgerId: row.original.complianceLedger?.id,
                      })}
                    >
                      <HistoryEduOutlinedIcon />
                    </IconButton>
                  )}
                  <IconButton
                    id={`open-sidebar-button-${row.original.id}`}
                    tooltip="Open sidebar"
                    useGlobalTooltip
                    onClick={(event) =>
                      onDetailTaskViewClick(event, row.original)
                    }
                  >
                    <ViewSidebarOutlinedIcon />
                  </IconButton>
                  {discriminator === 'associatedTasks' && (
                    <IconButton
                      id={`edit-task-button-${row.original.id}`}
                      tooltip="Edit Task"
                      useGlobalTooltip
                      onClick={() => onChildTaskSliderClick(row.original)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </>
              )}

              <IconButton
                id={`open-detail-page-button-${row.original.id}`}
                tooltip="Open detail page"
                useGlobalTooltip
                component={Link}
                to={routes.maintenanceItem({
                  orgName,
                  id: row.original.id,
                })}
              >
                <ArrowForwardOutlinedIcon />
              </IconButton>
            </div>
          )
        },
      },
    ],
    [variant, aircrafts]
  )

  const { enabledColumns } = useMaintenanceItemTable(variant)
  const allColumns = columns.map((col) => col.accessorKey)
  const computeColumnVisibility = (
    enabledColumns: string[],
    alwaysShownColumns: string[] = ['tailNumber', 'actions']
  ) => {
    // Start with all columns hidden
    const baseVisibility = allColumns.reduce((acc, col) => {
      acc[col] = false
      return acc
    }, {})

    // Enable columns that should be visible
    const visibleColumns = enabledColumns.reduce((acc, col) => {
      acc[col] = isColumnVisible(col)
      return acc
    }, {})

    // Always show specific columns (unless explicitly hidden by isColumnVisible)
    const alwaysVisible = alwaysShownColumns.reduce((acc, col) => {
      acc[col] = isColumnVisible(col)
      return acc
    }, {})

    return {
      ...baseVisibility,
      ...visibleColumns,
      ...alwaysVisible,
    }
  }

  const [columnVisibility, setColumnVisibility] = useState({})

  useEffect(() => {
    if (!hasAircraftListLoaded) return
    const newVisibility = computeColumnVisibility(enabledColumns)
    setColumnVisibility(newVisibility)
  }, [enabledColumns, aircraftFilter, hasAircraftListLoaded])

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})

  const table = useMaterialReactTable({
    columns,
    enableStickyHeader: true,
    data: maintenanceItems,
    enableMultiRowSelection: true,
    enableRowSelection: disableTaskSelection
      ? false
      : (row) =>
          selectedAircraft
            ? selectedAircraft === row.original.aircraftId
            : true,
    enablePagination: false,
    enableBottomToolbar: true,
    enableTopToolbar: false,
    enableRowVirtualization,
    enableColumnActions: false,
    manualSorting: true,
    enableSortingRemoval: false,
    layoutMode: 'grid',
    rowVirtualizerOptions: (props) => ({
      ...props,
      overscan: 30,
      getScrollElement: () => document.querySelector('.MuiTableContainer-root'),
      estimateSize: () => 100, // Fixed row height
      measureSize: false, // Disable dynamic measurement
    }),
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#fafafa',
        color: 'grey',
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      return {
        onClick: (event) => {
          // Don't trigger row selection if clicking on action buttons
          if ((event.target as HTMLElement).closest('.MuiIconButton-root')) {
            return
          }
          // Don't toggle if user is dragging to select text
          if (window.getSelection()?.toString()) {
            return
          }
          // Toggle row selection
          row.toggleSelected(!row.getIsSelected())
        },
        sx: {
          height: '100px',
        },
      }
    },
    muiTableContainerProps: {
      sx: {
        height: tableHeightSx,
        overflow: 'auto',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
      },
      onScroll: (event) => {
        const target = event.target as HTMLDivElement

        const scrollPosition = target.scrollTop + target.clientHeight
        const threshold = target.scrollHeight * 0.8 // Load when within 20% of bottom

        if (
          scrollPosition > threshold &&
          hasNextPage &&
          !initialLoading &&
          !paginationLoading
        ) {
          fetchNextMaintenancePage()
        }
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        borderRadius: '8px',
        border: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        zIndex: 1,
      },
    },
    renderBottomToolbarCustomActions: () => {
      return (
        <Typography className="flex items-center">
          Fetched {maintenanceItems.length} of {totalCount} total rows
        </Typography>
      )
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updaterOrValue) => {
      // Get the new selection state
      const newRowSelection =
        typeof updaterOrValue === 'function'
          ? updaterOrValue(rowSelection)
          : updaterOrValue

      // Update internal row selection state first
      setRowSelection(newRowSelection)

      // Get selected IDs from the new selection state
      const selectedIds = Object.keys(newRowSelection).filter(
        (id) => newRowSelection[id]
      )

      // Get the full maintenance items for selected rows
      const selectedItems = maintenanceItems.filter((item) =>
        selectedIds.includes(item.id)
      )

      // Update redux state
      if (selectedIds.length > 0) {
        selectMaintenanceItem(selectedItems)
        // Notify parent component
        onSelectItem?.(selectedIds)
      } else {
        unselectAll()
        onSelectItem?.([])
      }
    },
    state: {
      rowSelection,
      sorting,
      isLoading:
        initialLoading || isAircraftListLoading || tableColumnConfigLoading,
      showProgressBars: paginationLoading,
      showSkeletons: false,
      columnVisibility,
      columnOrder: [
        'mrt-row-select',
        'tailNumber',
        ...enabledColumns,
        'actions',
      ],
      columnPinning: {
        right: ['actions'],
        left: ['mrt-row-select'],
      },
    },
    onSortingChange: setSorting,
  })

  if (initialLoading || isAircraftListLoading || tableColumnConfigLoading)
    return <Loading />

  if (error) return <ApiError />

  return (
    <div className="flex-1 overflow-hidden">
      <MaterialReactTable table={table} />

      <LineItemDetailsDrawer
        open={openDetailedTaskView}
        onClose={() => {
          setOpenDetailedTaskView(false)
        }}
        parentTask={detailedTaskView}
        aircraft={aircrafts[detailedTaskView?.aircraftId]}
        activeTab={detailedTaskViewTab}
        setActiveTab={setDetailedTaskViewTab}
        refetch={() => {
          table.resetRowSelection()
          loadMaintenanceItems(queryVariables, true)
        }}
      />
    </div>
  )
}

export default TableWithData
